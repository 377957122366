import { default as api_45legacyQpuSKSWPsWMeta } from "/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awards9DxpT0SbvhMeta } from "/codebuild/output/src3863098639/src/horizon/pages/awards.vue?macro=true";
import { default as programs3AOIhtQPK5Meta } from "/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93EIUKoayyuIMeta } from "/codebuild/output/src3863098639/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93pp6lSAotTOMeta } from "/codebuild/output/src3863098639/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as index7zk6qY002UMeta } from "/codebuild/output/src3863098639/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta } from "/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93pSLxhrAktEMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93m4a9j0Y3asMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutG08Zo5gcuKMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/about.vue?macro=true";
import { default as indexaImQvaIrCGMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productVA9s6kPRvhMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as index4zc6nXJqrpMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as salesRE52yM7LN6Meta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archivezDlF1lzUdBMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexZhmq51FU9QMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexKZycUPRRvuMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93JbsjgQJcpQMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexmb64YaXljrMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as contactGz7M5672rdMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue?macro=true";
import { default as diversitydyD6tXFAUhMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue?macro=true";
import { default as index0CckPMFu1qMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencypzl0sgYriCMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetycmmzF72W40Meta } from "/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilityMW5ueZOERgMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newsgC9Mp3wa5yMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexRxCxXOql2vMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releases0Mg4e1B6wgMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacycejFsBWeQpMeta } from "/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolyKRHnAol8KMeta } from "/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93WUM09MRxguMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_930K5PFoeMiOMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexxZEBAgOPUmMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93xuN9NAsILNMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93laKIbJSfdsMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as index7untUdgWv7Meta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93xIHjG2scNsMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondbh9oYT2b72Meta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performanceMmY6ByQvuUMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceIOrOIwtaitMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexgXTmlY12qbMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleet4hJig4SgYlMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceRju40qulUDMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performancepR6zV2ybhrMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovatorlNjBRKsr0dMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexmqU8RM7vk5Meta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetJkZRJS67Q8Meta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetc0TG3DWUDEMeta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexB7zjT3wUT1Meta } from "/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_9326xwN6b08ZMeta } from "/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexnJR4kwX1B8Meta } from "/codebuild/output/src3863098639/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45appYiL93CLcvfMeta } from "/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93y3VwH3ijOAMeta } from "/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93yKzVIhFOXIMeta } from "/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93U768FfGWB4Meta } from "/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93WchJUDi5JAMeta } from "/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_93aagkLVUppWMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talentWLqIcG7XHTMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexZJEOv5wLMNMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsblJjjHX7DuMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsGLItHzdrGHMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexWJI83N62pXMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programzaSqVdxas8Meta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roi6gCzNpkzXbMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexENxrdVxdGEMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futuree7frHo5JVSMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsdSI09jSetTMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexRCTwowXW4eMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataj28ri4bXwCMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93SPmKtLb1QVMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexWUlZ64KpHqMeta } from "/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexhjlZ02cUEFMeta } from "/codebuild/output/src3863098639/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_93EE7IHzZBpLMeta } from "/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93E2ci76esoTMeta } from "/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexuyOFw76pPKMeta } from "/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiestlNl4VkCP8Meta } from "/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexqdiNVEadLOMeta } from "/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexwOHy0atksAMeta } from "/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue?macro=true";
import { default as learn_45more3pAEqWdyZLMeta } from "/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue?macro=true";
import { default as candidate_45privacy_45noticesPIoeJo4eeMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentlVXvDbYqg7Meta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyxIrnS5HxPrMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumLG9b50gm4fMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsnGvFqngXOXMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantyPFBg6KynHGMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slanzaiBNW7LhMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductIUUKVYpNXjMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45servicesj6RgclCDqMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyaDC5G9dVb9Meta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdatacICpL7NClFMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securitymWFKlXBN9xMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportH0ofYomYcFMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45serviceIoEaa1Gxy7Meta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesMvEeBisztLMeta } from "/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingojgPla2JoKMeta } from "/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93H5kPJcMJ9fMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93EhqHMnYdu4Meta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingKgZxoU78QRMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camst0k3JFuekSMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as construction9EDcM94YgTMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementQXcWNKA3xrMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwideGJHjHEw3G7Meta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingRfaSFssRSpMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as unifirstra1LtM3kwsMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue?macro=true";
import { default as pricingMNe5ILFBYiMeta } from "/codebuild/output/src3863098639/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_930S4OZxpSg4Meta } from "/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93nMart5Xq35Meta } from "/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45tracking0T4L0qp016Meta } from "/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackinglhgBksmxYrMeta } from "/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringBQeGjsFlRgMeta } from "/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailerse9utMMHHwjMeta } from "/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackinghp76qYtzOeMeta } from "/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as index6ySWJs70B2Meta } from "/codebuild/output/src3863098639/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93EefDTQIqGLMeta } from "/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexYMBOkOpMfUMeta } from "/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingLr19zOofd4Meta } from "/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexNNXyPl0cpIMeta } from "/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as referralsiNhJyF1AyaMeta } from "/codebuild/output/src3863098639/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93HFM4zbbog6Meta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetsyrSO09d6ZJMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93d1zNoluihcMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centerDRs3069YgjMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as _91slug_93k0xF5NubprMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_937mxX4tNrO9Meta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexHeqiZtuSEkMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexglmKqaSEXaMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_936yMryG0j4iMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93mDc5oseWTDMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as index3ikmdZymlMMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsaraFRTP9D42J4Meta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programsmbZloWHpzNMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as index4ZimGfJbxuMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesJA106a29n4Meta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsara17EphE54zlMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as touru6TTYStj3UMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosNVtEp2QGnmMeta } from "/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexY4xBPykOXeMeta } from "/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesUYL8syPueuMeta } from "/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitc9UFn9dovGMeta } from "/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93EIZFxQMUOTMeta } from "/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexFy0M1PAaDQMeta } from "/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue?macro=true";
import { default as security7lRdzuPB6xMeta } from "/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spaceyhQaIpGdb8Meta } from "/codebuild/output/src3863098639/src/horizon/pages/space.vue?macro=true";
import { default as sparkkez9nGBlvoMeta } from "/codebuild/output/src3863098639/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93oBBhFtmnQ9Meta } from "/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93Gyi3SmoSHnMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93jCRc3S3qNSMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policy8NixGL9zDkMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faq7p5BCSzcLKMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishylxVKF9XHJMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingiJLFBkGi2oMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexlyFPWGe0f2Meta } from "/codebuild/output/src3863098639/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialOLJRC2ikC7Meta } from "/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementecIyLAtplUMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyinNrZVgiVsMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexklJPqTVvESMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuresUUDptyfwvtMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listH1aP4A6uJhMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articles35BOrk2MaXMeta } from "/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pagekwuhtRL1oGMeta } from "/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruiting0md9fKbwk5Meta } from "/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralVbKvFlodUCMeta } from "/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyoudiijGUmQYvMeta } from "/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue?macro=true";
import { default as elitehFcZWe7LCDMeta } from "/codebuild/output/src3863098639/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusMfPSOKz7TnMeta } from "/codebuild/output/src3863098639/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierwBv4D9fceOMeta } from "/codebuild/output/src3863098639/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterXcHBagvgkEMeta } from "/codebuild/output/src3863098639/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processREmDvjBLPpMeta } from "/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportqCvJQbKLIIMeta } from "/codebuild/output/src3863098639/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93nj3wHXoVONMeta } from "/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexd7ksET0HKvMeta } from "/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_938L642VeZp5Meta } from "/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___en-US",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___ca",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___nl",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___uk",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___de",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/de/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___mx",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___fr",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyQpuSKSWPsWMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyQpuSKSWPsWMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyQpuSKSWPsWMeta || {},
    alias: api_45legacyQpuSKSWPsWMeta?.alias || [],
    redirect: api_45legacyQpuSKSWPsWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___en-US",
    path: awards9DxpT0SbvhMeta?.path ?? "/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___ca",
    path: awards9DxpT0SbvhMeta?.path ?? "/ca/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___nl",
    path: awards9DxpT0SbvhMeta?.path ?? "/nl/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___uk",
    path: awards9DxpT0SbvhMeta?.path ?? "/uk/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___de",
    path: awards9DxpT0SbvhMeta?.path ?? "/de/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___mx",
    path: awards9DxpT0SbvhMeta?.path ?? "/mx/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___fr",
    path: awards9DxpT0SbvhMeta?.path ?? "/fr/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards9DxpT0SbvhMeta?.name ?? "awards___fr-ca",
    path: awards9DxpT0SbvhMeta?.path ?? "/fr-ca/awards",
    meta: awards9DxpT0SbvhMeta || {},
    alias: awards9DxpT0SbvhMeta?.alias || [],
    redirect: awards9DxpT0SbvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___en-US",
    path: programs3AOIhtQPK5Meta?.path ?? "/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___ca",
    path: programs3AOIhtQPK5Meta?.path ?? "/ca/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___nl",
    path: programs3AOIhtQPK5Meta?.path ?? "/nl/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___uk",
    path: programs3AOIhtQPK5Meta?.path ?? "/uk/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___de",
    path: programs3AOIhtQPK5Meta?.path ?? "/de/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___mx",
    path: programs3AOIhtQPK5Meta?.path ?? "/mx/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___fr",
    path: programs3AOIhtQPK5Meta?.path ?? "/fr/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs3AOIhtQPK5Meta?.name ?? "benefits-programs___fr-ca",
    path: programs3AOIhtQPK5Meta?.path ?? "/fr-ca/benefits/programs",
    meta: programs3AOIhtQPK5Meta || {},
    alias: programs3AOIhtQPK5Meta?.alias || [],
    redirect: programs3AOIhtQPK5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIUKoayyuIMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93EIUKoayyuIMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93EIUKoayyuIMeta || {},
    alias: _91slug_93EIUKoayyuIMeta?.alias || [],
    redirect: _91slug_93EIUKoayyuIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIUKoayyuIMeta?.name ?? "blog-slug___ca",
    path: _91slug_93EIUKoayyuIMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93EIUKoayyuIMeta || {},
    alias: _91slug_93EIUKoayyuIMeta?.alias || [],
    redirect: _91slug_93EIUKoayyuIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIUKoayyuIMeta?.name ?? "blog-slug___mx",
    path: _91slug_93EIUKoayyuIMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93EIUKoayyuIMeta || {},
    alias: _91slug_93EIUKoayyuIMeta?.alias || [],
    redirect: _91slug_93EIUKoayyuIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIUKoayyuIMeta?.name ?? "blog-slug___uk",
    path: _91slug_93EIUKoayyuIMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93EIUKoayyuIMeta || {},
    alias: _91slug_93EIUKoayyuIMeta?.alias || [],
    redirect: _91slug_93EIUKoayyuIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIUKoayyuIMeta?.name ?? "blog-slug___fr",
    path: _91slug_93EIUKoayyuIMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93EIUKoayyuIMeta || {},
    alias: _91slug_93EIUKoayyuIMeta?.alias || [],
    redirect: _91slug_93EIUKoayyuIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIUKoayyuIMeta?.name ?? "blog-slug___de",
    path: _91slug_93EIUKoayyuIMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93EIUKoayyuIMeta || {},
    alias: _91slug_93EIUKoayyuIMeta?.alias || [],
    redirect: _91slug_93EIUKoayyuIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pp6lSAotTOMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93pp6lSAotTOMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93pp6lSAotTOMeta || {},
    alias: _91slug_93pp6lSAotTOMeta?.alias || [],
    redirect: _91slug_93pp6lSAotTOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pp6lSAotTOMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93pp6lSAotTOMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93pp6lSAotTOMeta || {},
    alias: _91slug_93pp6lSAotTOMeta?.alias || [],
    redirect: _91slug_93pp6lSAotTOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pp6lSAotTOMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93pp6lSAotTOMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93pp6lSAotTOMeta || {},
    alias: _91slug_93pp6lSAotTOMeta?.alias || [],
    redirect: _91slug_93pp6lSAotTOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pp6lSAotTOMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93pp6lSAotTOMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93pp6lSAotTOMeta || {},
    alias: _91slug_93pp6lSAotTOMeta?.alias || [],
    redirect: _91slug_93pp6lSAotTOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pp6lSAotTOMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93pp6lSAotTOMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93pp6lSAotTOMeta || {},
    alias: _91slug_93pp6lSAotTOMeta?.alias || [],
    redirect: _91slug_93pp6lSAotTOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pp6lSAotTOMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93pp6lSAotTOMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93pp6lSAotTOMeta || {},
    alias: _91slug_93pp6lSAotTOMeta?.alias || [],
    redirect: _91slug_93pp6lSAotTOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index7zk6qY002UMeta?.name ?? "blog___en-US",
    path: index7zk6qY002UMeta?.path ?? "/blog",
    meta: index7zk6qY002UMeta || {},
    alias: index7zk6qY002UMeta?.alias || [],
    redirect: index7zk6qY002UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index7zk6qY002UMeta?.name ?? "blog___ca",
    path: index7zk6qY002UMeta?.path ?? "/ca/blog",
    meta: index7zk6qY002UMeta || {},
    alias: index7zk6qY002UMeta?.alias || [],
    redirect: index7zk6qY002UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index7zk6qY002UMeta?.name ?? "blog___mx",
    path: index7zk6qY002UMeta?.path ?? "/mx/blog",
    meta: index7zk6qY002UMeta || {},
    alias: index7zk6qY002UMeta?.alias || [],
    redirect: index7zk6qY002UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index7zk6qY002UMeta?.name ?? "blog___uk",
    path: index7zk6qY002UMeta?.path ?? "/uk/blog",
    meta: index7zk6qY002UMeta || {},
    alias: index7zk6qY002UMeta?.alias || [],
    redirect: index7zk6qY002UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index7zk6qY002UMeta?.name ?? "blog___fr",
    path: index7zk6qY002UMeta?.path ?? "/fr/blog",
    meta: index7zk6qY002UMeta || {},
    alias: index7zk6qY002UMeta?.alias || [],
    redirect: index7zk6qY002UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index7zk6qY002UMeta?.name ?? "blog___de",
    path: index7zk6qY002UMeta?.path ?? "/de/blog",
    meta: index7zk6qY002UMeta || {},
    alias: index7zk6qY002UMeta?.alias || [],
    redirect: index7zk6qY002UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondkbfel9tl5UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pSLxhrAktEMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93pSLxhrAktEMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93pSLxhrAktEMeta || {},
    alias: _91slug_93pSLxhrAktEMeta?.alias || [],
    redirect: _91slug_93pSLxhrAktEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___en-US",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___ca",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___nl",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___uk",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___de",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___mx",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___fr",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93m4a9j0Y3asMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_93m4a9j0Y3asMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93m4a9j0Y3asMeta || {},
    alias: _91slug_93m4a9j0Y3asMeta?.alias || [],
    redirect: _91slug_93m4a9j0Y3asMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___en-US",
    path: aboutG08Zo5gcuKMeta?.path ?? "/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___ca",
    path: aboutG08Zo5gcuKMeta?.path ?? "/ca/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___nl",
    path: aboutG08Zo5gcuKMeta?.path ?? "/nl/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___uk",
    path: aboutG08Zo5gcuKMeta?.path ?? "/uk/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___de",
    path: aboutG08Zo5gcuKMeta?.path ?? "/de/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___mx",
    path: aboutG08Zo5gcuKMeta?.path ?? "/mx/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___fr",
    path: aboutG08Zo5gcuKMeta?.path ?? "/fr/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutG08Zo5gcuKMeta?.name ?? "company-about___fr-ca",
    path: aboutG08Zo5gcuKMeta?.path ?? "/fr-ca/company/about",
    meta: aboutG08Zo5gcuKMeta || {},
    alias: aboutG08Zo5gcuKMeta?.alias || [],
    redirect: aboutG08Zo5gcuKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___en-US",
    path: indexaImQvaIrCGMeta?.path ?? "/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___ca",
    path: indexaImQvaIrCGMeta?.path ?? "/ca/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___nl",
    path: indexaImQvaIrCGMeta?.path ?? "/nl/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___uk",
    path: indexaImQvaIrCGMeta?.path ?? "/uk/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___de",
    path: indexaImQvaIrCGMeta?.path ?? "/de/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___mx",
    path: indexaImQvaIrCGMeta?.path ?? "/mx/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___fr",
    path: indexaImQvaIrCGMeta?.path ?? "/fr/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexaImQvaIrCGMeta?.name ?? "company-careers-benefits___fr-ca",
    path: indexaImQvaIrCGMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexaImQvaIrCGMeta || {},
    alias: indexaImQvaIrCGMeta?.alias || [],
    redirect: indexaImQvaIrCGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productVA9s6kPRvhMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productVA9s6kPRvhMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productVA9s6kPRvhMeta || {},
    alias: engineering_45and_45productVA9s6kPRvhMeta?.alias || [],
    redirect: engineering_45and_45productVA9s6kPRvhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___en-US",
    path: index4zc6nXJqrpMeta?.path ?? "/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___ca",
    path: index4zc6nXJqrpMeta?.path ?? "/ca/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___nl",
    path: index4zc6nXJqrpMeta?.path ?? "/nl/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___uk",
    path: index4zc6nXJqrpMeta?.path ?? "/uk/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___de",
    path: index4zc6nXJqrpMeta?.path ?? "/de/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___mx",
    path: index4zc6nXJqrpMeta?.path ?? "/mx/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___fr",
    path: index4zc6nXJqrpMeta?.path ?? "/fr/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index4zc6nXJqrpMeta?.name ?? "company-careers-departments___fr-ca",
    path: index4zc6nXJqrpMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: index4zc6nXJqrpMeta || {},
    alias: index4zc6nXJqrpMeta?.alias || [],
    redirect: index4zc6nXJqrpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___en-US",
    path: salesRE52yM7LN6Meta?.path ?? "/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___ca",
    path: salesRE52yM7LN6Meta?.path ?? "/ca/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___nl",
    path: salesRE52yM7LN6Meta?.path ?? "/nl/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___uk",
    path: salesRE52yM7LN6Meta?.path ?? "/uk/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___de",
    path: salesRE52yM7LN6Meta?.path ?? "/de/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___mx",
    path: salesRE52yM7LN6Meta?.path ?? "/mx/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___fr",
    path: salesRE52yM7LN6Meta?.path ?? "/fr/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesRE52yM7LN6Meta?.name ?? "company-careers-departments-sales___fr-ca",
    path: salesRE52yM7LN6Meta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: salesRE52yM7LN6Meta || {},
    alias: salesRE52yM7LN6Meta?.alias || [],
    redirect: salesRE52yM7LN6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivezDlF1lzUdBMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archivezDlF1lzUdBMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivezDlF1lzUdBMeta || {},
    alias: emerging_45talent_45archivezDlF1lzUdBMeta?.alias || [],
    redirect: emerging_45talent_45archivezDlF1lzUdBMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___en-US",
    path: indexZhmq51FU9QMeta?.path ?? "/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___ca",
    path: indexZhmq51FU9QMeta?.path ?? "/ca/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___nl",
    path: indexZhmq51FU9QMeta?.path ?? "/nl/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___uk",
    path: indexZhmq51FU9QMeta?.path ?? "/uk/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___de",
    path: indexZhmq51FU9QMeta?.path ?? "/de/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___mx",
    path: indexZhmq51FU9QMeta?.path ?? "/mx/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___fr",
    path: indexZhmq51FU9QMeta?.path ?? "/fr/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhmq51FU9QMeta?.name ?? "company-careers___fr-ca",
    path: indexZhmq51FU9QMeta?.path ?? "/fr-ca/company/careers",
    meta: indexZhmq51FU9QMeta || {},
    alias: indexZhmq51FU9QMeta?.alias || [],
    redirect: indexZhmq51FU9QMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___en-US",
    path: indexKZycUPRRvuMeta?.path ?? "/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___ca",
    path: indexKZycUPRRvuMeta?.path ?? "/ca/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___nl",
    path: indexKZycUPRRvuMeta?.path ?? "/nl/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___uk",
    path: indexKZycUPRRvuMeta?.path ?? "/uk/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___de",
    path: indexKZycUPRRvuMeta?.path ?? "/de/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___mx",
    path: indexKZycUPRRvuMeta?.path ?? "/mx/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___fr",
    path: indexKZycUPRRvuMeta?.path ?? "/fr/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexKZycUPRRvuMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexKZycUPRRvuMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexKZycUPRRvuMeta || {},
    alias: indexKZycUPRRvuMeta?.alias || [],
    redirect: indexKZycUPRRvuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JbsjgQJcpQMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93JbsjgQJcpQMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93JbsjgQJcpQMeta || {},
    alias: _91slug_93JbsjgQJcpQMeta?.alias || [],
    redirect: _91slug_93JbsjgQJcpQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___en-US",
    path: indexmb64YaXljrMeta?.path ?? "/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___ca",
    path: indexmb64YaXljrMeta?.path ?? "/ca/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___nl",
    path: indexmb64YaXljrMeta?.path ?? "/nl/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___uk",
    path: indexmb64YaXljrMeta?.path ?? "/uk/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___de",
    path: indexmb64YaXljrMeta?.path ?? "/de/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___mx",
    path: indexmb64YaXljrMeta?.path ?? "/mx/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___fr",
    path: indexmb64YaXljrMeta?.path ?? "/fr/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexmb64YaXljrMeta?.name ?? "company-careers-roles___fr-ca",
    path: indexmb64YaXljrMeta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexmb64YaXljrMeta || {},
    alias: indexmb64YaXljrMeta?.alias || [],
    redirect: indexmb64YaXljrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___en-US",
    path: contactGz7M5672rdMeta?.path ?? "/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___ca",
    path: contactGz7M5672rdMeta?.path ?? "/ca/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___nl",
    path: contactGz7M5672rdMeta?.path ?? "/nl/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___uk",
    path: contactGz7M5672rdMeta?.path ?? "/uk/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___de",
    path: contactGz7M5672rdMeta?.path ?? "/de/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___mx",
    path: contactGz7M5672rdMeta?.path ?? "/mx/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___fr",
    path: contactGz7M5672rdMeta?.path ?? "/fr/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactGz7M5672rdMeta?.name ?? "company-contact___fr-ca",
    path: contactGz7M5672rdMeta?.path ?? "/fr-ca/company/contact",
    meta: contactGz7M5672rdMeta || {},
    alias: contactGz7M5672rdMeta?.alias || [],
    redirect: contactGz7M5672rdMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___en-US",
    path: diversitydyD6tXFAUhMeta?.path ?? "/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___ca",
    path: diversitydyD6tXFAUhMeta?.path ?? "/ca/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___nl",
    path: diversitydyD6tXFAUhMeta?.path ?? "/nl/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___uk",
    path: diversitydyD6tXFAUhMeta?.path ?? "/uk/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___de",
    path: diversitydyD6tXFAUhMeta?.path ?? "/de/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___mx",
    path: diversitydyD6tXFAUhMeta?.path ?? "/mx/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___fr",
    path: diversitydyD6tXFAUhMeta?.path ?? "/fr/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitydyD6tXFAUhMeta?.name ?? "company-diversity___fr-ca",
    path: diversitydyD6tXFAUhMeta?.path ?? "/fr-ca/company/diversity",
    meta: diversitydyD6tXFAUhMeta || {},
    alias: diversitydyD6tXFAUhMeta?.alias || [],
    redirect: diversitydyD6tXFAUhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___en-US",
    path: index0CckPMFu1qMeta?.path ?? "/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___ca",
    path: index0CckPMFu1qMeta?.path ?? "/ca/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___nl",
    path: index0CckPMFu1qMeta?.path ?? "/nl/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___uk",
    path: index0CckPMFu1qMeta?.path ?? "/uk/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___de",
    path: index0CckPMFu1qMeta?.path ?? "/de/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___mx",
    path: index0CckPMFu1qMeta?.path ?? "/mx/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___fr",
    path: index0CckPMFu1qMeta?.path ?? "/fr/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index0CckPMFu1qMeta?.name ?? "company___fr-ca",
    path: index0CckPMFu1qMeta?.path ?? "/fr-ca/company",
    meta: index0CckPMFu1qMeta || {},
    alias: index0CckPMFu1qMeta?.alias || [],
    redirect: index0CckPMFu1qMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencypzl0sgYriCMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencypzl0sgYriCMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencypzl0sgYriCMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencypzl0sgYriCMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencypzl0sgYriCMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencypzl0sgYriCMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencypzl0sgYriCMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencypzl0sgYriCMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencypzl0sgYriCMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencypzl0sgYriCMeta || {},
    alias: efficiencypzl0sgYriCMeta?.alias || [],
    redirect: efficiencypzl0sgYriCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___en-US",
    path: safetycmmzF72W40Meta?.path ?? "/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___ca",
    path: safetycmmzF72W40Meta?.path ?? "/ca/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___nl",
    path: safetycmmzF72W40Meta?.path ?? "/nl/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___uk",
    path: safetycmmzF72W40Meta?.path ?? "/uk/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___de",
    path: safetycmmzF72W40Meta?.path ?? "/de/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___mx",
    path: safetycmmzF72W40Meta?.path ?? "/mx/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___fr",
    path: safetycmmzF72W40Meta?.path ?? "/fr/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetycmmzF72W40Meta?.name ?? "company-mission-safety___fr-ca",
    path: safetycmmzF72W40Meta?.path ?? "/fr-ca/company/mission/safety",
    meta: safetycmmzF72W40Meta || {},
    alias: safetycmmzF72W40Meta?.alias || [],
    redirect: safetycmmzF72W40Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityMW5ueZOERgMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilityMW5ueZOERgMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilityMW5ueZOERgMeta || {},
    alias: sustainabilityMW5ueZOERgMeta?.alias || [],
    redirect: sustainabilityMW5ueZOERgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsgC9Mp3wa5yMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newsgC9Mp3wa5yMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newsgC9Mp3wa5yMeta || {},
    alias: in_45the_45newsgC9Mp3wa5yMeta?.alias || [],
    redirect: in_45the_45newsgC9Mp3wa5yMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexRxCxXOql2vMeta?.name ?? "company-news___en-US",
    path: indexRxCxXOql2vMeta?.path ?? "/company/news",
    meta: indexRxCxXOql2vMeta || {},
    alias: indexRxCxXOql2vMeta?.alias || [],
    redirect: indexRxCxXOql2vMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexRxCxXOql2vMeta?.name ?? "company-news___ca",
    path: indexRxCxXOql2vMeta?.path ?? "/ca/company/news",
    meta: indexRxCxXOql2vMeta || {},
    alias: indexRxCxXOql2vMeta?.alias || [],
    redirect: indexRxCxXOql2vMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexRxCxXOql2vMeta?.name ?? "company-news___mx",
    path: indexRxCxXOql2vMeta?.path ?? "/mx/company/news",
    meta: indexRxCxXOql2vMeta || {},
    alias: indexRxCxXOql2vMeta?.alias || [],
    redirect: indexRxCxXOql2vMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexRxCxXOql2vMeta?.name ?? "company-news___uk",
    path: indexRxCxXOql2vMeta?.path ?? "/uk/company/news",
    meta: indexRxCxXOql2vMeta || {},
    alias: indexRxCxXOql2vMeta?.alias || [],
    redirect: indexRxCxXOql2vMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___de",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releases0Mg4e1B6wgMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releases0Mg4e1B6wgMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releases0Mg4e1B6wgMeta || {},
    alias: press_45releases0Mg4e1B6wgMeta?.alias || [],
    redirect: press_45releases0Mg4e1B6wgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___en-US",
    path: privacycejFsBWeQpMeta?.path ?? "/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___ca",
    path: privacycejFsBWeQpMeta?.path ?? "/ca/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___nl",
    path: privacycejFsBWeQpMeta?.path ?? "/nl/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___uk",
    path: privacycejFsBWeQpMeta?.path ?? "/uk/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___de",
    path: privacycejFsBWeQpMeta?.path ?? "/de/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___mx",
    path: privacycejFsBWeQpMeta?.path ?? "/mx/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___fr",
    path: privacycejFsBWeQpMeta?.path ?? "/fr/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacycejFsBWeQpMeta?.name ?? "company-privacy___fr-ca",
    path: privacycejFsBWeQpMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacycejFsBWeQpMeta || {},
    alias: privacycejFsBWeQpMeta?.alias || [],
    redirect: privacycejFsBWeQpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolyKRHnAol8KMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolyKRHnAol8KMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolyKRHnAol8KMeta || {},
    alias: cost_45assessment_45toolyKRHnAol8KMeta?.alias || [],
    redirect: cost_45assessment_45toolyKRHnAol8KMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___ca",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___nl",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___uk",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___de",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___mx",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___fr",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUM09MRxguMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93WUM09MRxguMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93WUM09MRxguMeta || {},
    alias: _91slug_93WUM09MRxguMeta?.alias || [],
    redirect: _91slug_93WUM09MRxguMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___en-US",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___ca",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___nl",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___uk",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___de",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___mx",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___fr",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930K5PFoeMiOMeta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_930K5PFoeMiOMeta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_930K5PFoeMiOMeta || {},
    alias: _91slug_930K5PFoeMiOMeta?.alias || [],
    redirect: _91slug_930K5PFoeMiOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___en-US",
    path: indexxZEBAgOPUmMeta?.path ?? "/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___ca",
    path: indexxZEBAgOPUmMeta?.path ?? "/ca/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___nl",
    path: indexxZEBAgOPUmMeta?.path ?? "/nl/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___uk",
    path: indexxZEBAgOPUmMeta?.path ?? "/uk/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___de",
    path: indexxZEBAgOPUmMeta?.path ?? "/de/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___mx",
    path: indexxZEBAgOPUmMeta?.path ?? "/mx/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___fr",
    path: indexxZEBAgOPUmMeta?.path ?? "/fr/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZEBAgOPUmMeta?.name ?? "customers___fr-ca",
    path: indexxZEBAgOPUmMeta?.path ?? "/fr-ca/customers",
    meta: indexxZEBAgOPUmMeta || {},
    alias: indexxZEBAgOPUmMeta?.alias || [],
    redirect: indexxZEBAgOPUmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xuN9NAsILNMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93xuN9NAsILNMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93xuN9NAsILNMeta || {},
    alias: _91slug_93xuN9NAsILNMeta?.alias || [],
    redirect: _91slug_93xuN9NAsILNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93laKIbJSfdsMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93laKIbJSfdsMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93laKIbJSfdsMeta || {},
    alias: _91slug_93laKIbJSfdsMeta?.alias || [],
    redirect: _91slug_93laKIbJSfdsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___en-US",
    path: index7untUdgWv7Meta?.path ?? "/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___ca",
    path: index7untUdgWv7Meta?.path ?? "/ca/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___nl",
    path: index7untUdgWv7Meta?.path ?? "/nl/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___uk",
    path: index7untUdgWv7Meta?.path ?? "/uk/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___de",
    path: index7untUdgWv7Meta?.path ?? "/de/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___mx",
    path: index7untUdgWv7Meta?.path ?? "/mx/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___fr",
    path: index7untUdgWv7Meta?.path ?? "/fr/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index7untUdgWv7Meta?.name ?? "customers-tips___fr-ca",
    path: index7untUdgWv7Meta?.path ?? "/fr-ca/customers/tips",
    meta: index7untUdgWv7Meta || {},
    alias: index7untUdgWv7Meta?.alias || [],
    redirect: index7untUdgWv7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xIHjG2scNsMeta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93xIHjG2scNsMeta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93xIHjG2scNsMeta || {},
    alias: _91_46_46_46slug_93xIHjG2scNsMeta?.alias || [],
    redirect: _91_46_46_46slug_93xIHjG2scNsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondbh9oYT2b72Meta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondbh9oYT2b72Meta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondbh9oYT2b72Meta || {},
    alias: above_45beyondbh9oYT2b72Meta?.alias || [],
    redirect: above_45beyondbh9oYT2b72Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceMmY6ByQvuUMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performanceMmY6ByQvuUMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceMmY6ByQvuUMeta || {},
    alias: excellence_45performanceMmY6ByQvuUMeta?.alias || [],
    redirect: excellence_45performanceMmY6ByQvuUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceIOrOIwtaitMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45serviceIOrOIwtaitMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceIOrOIwtaitMeta || {},
    alias: excellence_45serviceIOrOIwtaitMeta?.alias || [],
    redirect: excellence_45serviceIOrOIwtaitMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___en-US",
    path: indexgXTmlY12qbMeta?.path ?? "/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___ca",
    path: indexgXTmlY12qbMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___nl",
    path: indexgXTmlY12qbMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___uk",
    path: indexgXTmlY12qbMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___de",
    path: indexgXTmlY12qbMeta?.path ?? "/de/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___mx",
    path: indexgXTmlY12qbMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___fr",
    path: indexgXTmlY12qbMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXTmlY12qbMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexgXTmlY12qbMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexgXTmlY12qbMeta || {},
    alias: indexgXTmlY12qbMeta?.alias || [],
    redirect: indexgXTmlY12qbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4hJig4SgYlMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleet4hJig4SgYlMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4hJig4SgYlMeta || {},
    alias: safest_45fleet4hJig4SgYlMeta?.alias || [],
    redirect: safest_45fleet4hJig4SgYlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceRju40qulUDMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceRju40qulUDMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceRju40qulUDMeta || {},
    alias: driver_45performanceRju40qulUDMeta?.alias || [],
    redirect: driver_45performanceRju40qulUDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancepR6zV2ybhrMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performancepR6zV2ybhrMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancepR6zV2ybhrMeta || {},
    alias: excellence_45performancepR6zV2ybhrMeta?.alias || [],
    redirect: excellence_45performancepR6zV2ybhrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorlNjBRKsr0dMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovatorlNjBRKsr0dMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorlNjBRKsr0dMeta || {},
    alias: fleet_45innovatorlNjBRKsr0dMeta?.alias || [],
    redirect: fleet_45innovatorlNjBRKsr0dMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___en-US",
    path: indexmqU8RM7vk5Meta?.path ?? "/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___ca",
    path: indexmqU8RM7vk5Meta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___nl",
    path: indexmqU8RM7vk5Meta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___uk",
    path: indexmqU8RM7vk5Meta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___de",
    path: indexmqU8RM7vk5Meta?.path ?? "/de/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___mx",
    path: indexmqU8RM7vk5Meta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___fr",
    path: indexmqU8RM7vk5Meta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqU8RM7vk5Meta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexmqU8RM7vk5Meta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexmqU8RM7vk5Meta || {},
    alias: indexmqU8RM7vk5Meta?.alias || [],
    redirect: indexmqU8RM7vk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetJkZRJS67Q8Meta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetJkZRJS67Q8Meta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetJkZRJS67Q8Meta || {},
    alias: public_45fleetJkZRJS67Q8Meta?.alias || [],
    redirect: public_45fleetJkZRJS67Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetc0TG3DWUDEMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetc0TG3DWUDEMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetc0TG3DWUDEMeta || {},
    alias: safest_45fleetc0TG3DWUDEMeta?.alias || [],
    redirect: safest_45fleetc0TG3DWUDEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetc0TG3DWUDEMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetc0TG3DWUDEMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetc0TG3DWUDEMeta || {},
    alias: safest_45fleetc0TG3DWUDEMeta?.alias || [],
    redirect: safest_45fleetc0TG3DWUDEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___en-US",
    path: indexB7zjT3wUT1Meta?.path ?? "/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___ca",
    path: indexB7zjT3wUT1Meta?.path ?? "/ca/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___nl",
    path: indexB7zjT3wUT1Meta?.path ?? "/nl/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___uk",
    path: indexB7zjT3wUT1Meta?.path ?? "/uk/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___de",
    path: indexB7zjT3wUT1Meta?.path ?? "/de/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___mx",
    path: indexB7zjT3wUT1Meta?.path ?? "/mx/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___fr",
    path: indexB7zjT3wUT1Meta?.path ?? "/fr/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexB7zjT3wUT1Meta?.name ?? "customers-topfleets___fr-ca",
    path: indexB7zjT3wUT1Meta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexB7zjT3wUT1Meta || {},
    alias: indexB7zjT3wUT1Meta?.alias || [],
    redirect: indexB7zjT3wUT1Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___en-US",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___ca",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___nl",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___uk",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___de",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___mx",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___fr",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9326xwN6b08ZMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_9326xwN6b08ZMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_9326xwN6b08ZMeta || {},
    alias: _91slug_9326xwN6b08ZMeta?.alias || [],
    redirect: _91slug_9326xwN6b08ZMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___en-US",
    path: indexnJR4kwX1B8Meta?.path ?? "/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___ca",
    path: indexnJR4kwX1B8Meta?.path ?? "/ca/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___nl",
    path: indexnJR4kwX1B8Meta?.path ?? "/nl/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___uk",
    path: indexnJR4kwX1B8Meta?.path ?? "/uk/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___de",
    path: indexnJR4kwX1B8Meta?.path ?? "/de/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___mx",
    path: indexnJR4kwX1B8Meta?.path ?? "/mx/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___fr",
    path: indexnJR4kwX1B8Meta?.path ?? "/fr/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexnJR4kwX1B8Meta?.name ?? "events___fr-ca",
    path: indexnJR4kwX1B8Meta?.path ?? "/fr-ca/events",
    meta: indexnJR4kwX1B8Meta || {},
    alias: indexnJR4kwX1B8Meta?.alias || [],
    redirect: indexnJR4kwX1B8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___en-US",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___ca",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___nl",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___uk",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___de",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/de/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___mx",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___fr",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appYiL93CLcvfMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45appYiL93CLcvfMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45appYiL93CLcvfMeta || {},
    alias: fleet_45appYiL93CLcvfMeta?.alias || [],
    redirect: fleet_45appYiL93CLcvfMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y3VwH3ijOAMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93y3VwH3ijOAMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93y3VwH3ijOAMeta || {},
    alias: _91slug_93y3VwH3ijOAMeta?.alias || [],
    redirect: _91slug_93y3VwH3ijOAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___de",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKzVIhFOXIMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93yKzVIhFOXIMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93yKzVIhFOXIMeta || {},
    alias: _91slug_93yKzVIhFOXIMeta?.alias || [],
    redirect: _91slug_93yKzVIhFOXIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U768FfGWB4Meta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_93U768FfGWB4Meta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_93U768FfGWB4Meta || {},
    alias: _91slug_93U768FfGWB4Meta?.alias || [],
    redirect: _91slug_93U768FfGWB4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___en-US",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___ca",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___nl",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___uk",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___de",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___mx",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___fr",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WchJUDi5JAMeta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93WchJUDi5JAMeta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93WchJUDi5JAMeta || {},
    alias: _91slug_93WchJUDi5JAMeta?.alias || [],
    redirect: _91slug_93WchJUDi5JAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___en-US",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___ca",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___nl",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___uk",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___de",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___mx",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___fr",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aagkLVUppWMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93aagkLVUppWMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93aagkLVUppWMeta || {},
    alias: _91slug_93aagkLVUppWMeta?.alias || [],
    redirect: _91slug_93aagkLVUppWMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentWLqIcG7XHTMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talentWLqIcG7XHTMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentWLqIcG7XHTMeta || {},
    alias: attract_45retain_45talentWLqIcG7XHTMeta?.alias || [],
    redirect: attract_45retain_45talentWLqIcG7XHTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta || {},
    alias: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitshcbbJR3g4AMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexZJEOv5wLMNMeta?.path ?? "/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexZJEOv5wLMNMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexZJEOv5wLMNMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexZJEOv5wLMNMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___de",
    path: indexZJEOv5wLMNMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexZJEOv5wLMNMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexZJEOv5wLMNMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJEOv5wLMNMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexZJEOv5wLMNMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexZJEOv5wLMNMeta || {},
    alias: indexZJEOv5wLMNMeta?.alias || [],
    redirect: indexZJEOv5wLMNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsblJjjHX7DuMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetsblJjjHX7DuMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsblJjjHX7DuMeta || {},
    alias: protect_45optimize_45assetsblJjjHX7DuMeta?.alias || [],
    redirect: protect_45optimize_45assetsblJjjHX7DuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsGLItHzdrGHMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsGLItHzdrGHMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsGLItHzdrGHMeta || {},
    alias: best_45dash_45camsGLItHzdrGHMeta?.alias || [],
    redirect: best_45dash_45camsGLItHzdrGHMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexWJI83N62pXMeta?.path ?? "/guides/fleet-safety",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___ca",
    path: indexWJI83N62pXMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___nl",
    path: indexWJI83N62pXMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___uk",
    path: indexWJI83N62pXMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___de",
    path: indexWJI83N62pXMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___mx",
    path: indexWJI83N62pXMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___fr",
    path: indexWJI83N62pXMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexWJI83N62pXMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexWJI83N62pXMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexWJI83N62pXMeta || {},
    alias: indexWJI83N62pXMeta?.alias || [],
    redirect: indexWJI83N62pXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programzaSqVdxas8Meta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programzaSqVdxas8Meta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programzaSqVdxas8Meta || {},
    alias: safety_45programzaSqVdxas8Meta?.alias || [],
    redirect: safety_45programzaSqVdxas8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roi6gCzNpkzXbMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roi6gCzNpkzXbMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roi6gCzNpkzXbMeta || {},
    alias: safety_45roi6gCzNpkzXbMeta?.alias || [],
    redirect: safety_45roi6gCzNpkzXbMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___en-US",
    path: indexENxrdVxdGEMeta?.path ?? "/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___ca",
    path: indexENxrdVxdGEMeta?.path ?? "/ca/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___nl",
    path: indexENxrdVxdGEMeta?.path ?? "/nl/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___uk",
    path: indexENxrdVxdGEMeta?.path ?? "/uk/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___de",
    path: indexENxrdVxdGEMeta?.path ?? "/de/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___mx",
    path: indexENxrdVxdGEMeta?.path ?? "/mx/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___fr",
    path: indexENxrdVxdGEMeta?.path ?? "/fr/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexENxrdVxdGEMeta?.name ?? "guides___fr-ca",
    path: indexENxrdVxdGEMeta?.path ?? "/fr-ca/guides",
    meta: indexENxrdVxdGEMeta || {},
    alias: indexENxrdVxdGEMeta?.alias || [],
    redirect: indexENxrdVxdGEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futuree7frHo5JVSMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futuree7frHo5JVSMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futuree7frHo5JVSMeta || {},
    alias: build_45for_45the_45futuree7frHo5JVSMeta?.alias || [],
    redirect: build_45for_45the_45futuree7frHo5JVSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsdSI09jSetTMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsdSI09jSetTMeta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsdSI09jSetTMeta || {},
    alias: empower_45your_45teamsdSI09jSetTMeta?.alias || [],
    redirect: empower_45your_45teamsdSI09jSetTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexRCTwowXW4eMeta?.path ?? "/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___ca",
    path: indexRCTwowXW4eMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___nl",
    path: indexRCTwowXW4eMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___uk",
    path: indexRCTwowXW4eMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___de",
    path: indexRCTwowXW4eMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___mx",
    path: indexRCTwowXW4eMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___fr",
    path: indexRCTwowXW4eMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexRCTwowXW4eMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexRCTwowXW4eMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexRCTwowXW4eMeta || {},
    alias: indexRCTwowXW4eMeta?.alias || [],
    redirect: indexRCTwowXW4eMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataj28ri4bXwCMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataj28ri4bXwCMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataj28ri4bXwCMeta || {},
    alias: take_45action_45with_45dataj28ri4bXwCMeta?.alias || [],
    redirect: take_45action_45with_45dataj28ri4bXwCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SPmKtLb1QVMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93SPmKtLb1QVMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93SPmKtLb1QVMeta || {},
    alias: _91slug_93SPmKtLb1QVMeta?.alias || [],
    redirect: _91slug_93SPmKtLb1QVMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indexWUlZ64KpHqMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indexWUlZ64KpHqMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indexWUlZ64KpHqMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indexWUlZ64KpHqMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indexWUlZ64KpHqMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indexWUlZ64KpHqMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indexWUlZ64KpHqMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexWUlZ64KpHqMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indexWUlZ64KpHqMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indexWUlZ64KpHqMeta || {},
    alias: indexWUlZ64KpHqMeta?.alias || [],
    redirect: indexWUlZ64KpHqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___en-US",
    path: indexhjlZ02cUEFMeta?.path ?? "/",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___ca",
    path: indexhjlZ02cUEFMeta?.path ?? "/ca",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___nl",
    path: indexhjlZ02cUEFMeta?.path ?? "/nl",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___uk",
    path: indexhjlZ02cUEFMeta?.path ?? "/uk",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___de",
    path: indexhjlZ02cUEFMeta?.path ?? "/de",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___mx",
    path: indexhjlZ02cUEFMeta?.path ?? "/mx",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___fr",
    path: indexhjlZ02cUEFMeta?.path ?? "/fr",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjlZ02cUEFMeta?.name ?? "index___fr-ca",
    path: indexhjlZ02cUEFMeta?.path ?? "/fr-ca",
    meta: indexhjlZ02cUEFMeta || {},
    alias: indexhjlZ02cUEFMeta?.alias || [],
    redirect: indexhjlZ02cUEFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___ca",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___nl",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___uk",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___de",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___mx",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___fr",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EE7IHzZBpLMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_93EE7IHzZBpLMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_93EE7IHzZBpLMeta || {},
    alias: _91slug_93EE7IHzZBpLMeta?.alias || [],
    redirect: _91slug_93EE7IHzZBpLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___ca",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___nl",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___uk",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___de",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___mx",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___fr",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E2ci76esoTMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93E2ci76esoTMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93E2ci76esoTMeta || {},
    alias: _91slug_93E2ci76esoTMeta?.alias || [],
    redirect: _91slug_93E2ci76esoTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___en-US",
    path: indexuyOFw76pPKMeta?.path ?? "/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___ca",
    path: indexuyOFw76pPKMeta?.path ?? "/ca/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___nl",
    path: indexuyOFw76pPKMeta?.path ?? "/nl/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___uk",
    path: indexuyOFw76pPKMeta?.path ?? "/uk/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___de",
    path: indexuyOFw76pPKMeta?.path ?? "/de/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___mx",
    path: indexuyOFw76pPKMeta?.path ?? "/mx/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___fr",
    path: indexuyOFw76pPKMeta?.path ?? "/fr/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexuyOFw76pPKMeta?.name ?? "industries___fr-ca",
    path: indexuyOFw76pPKMeta?.path ?? "/fr-ca/industries",
    meta: indexuyOFw76pPKMeta || {},
    alias: indexuyOFw76pPKMeta?.alias || [],
    redirect: indexuyOFw76pPKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___en-US",
    path: citiestlNl4VkCP8Meta?.path ?? "/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___ca",
    path: citiestlNl4VkCP8Meta?.path ?? "/ca/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___nl",
    path: citiestlNl4VkCP8Meta?.path ?? "/nl/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___uk",
    path: citiestlNl4VkCP8Meta?.path ?? "/uk/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___de",
    path: citiestlNl4VkCP8Meta?.path ?? "/de/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___mx",
    path: citiestlNl4VkCP8Meta?.path ?? "/mx/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___fr",
    path: citiestlNl4VkCP8Meta?.path ?? "/fr/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiestlNl4VkCP8Meta?.name ?? "insights-cities___fr-ca",
    path: citiestlNl4VkCP8Meta?.path ?? "/fr-ca/insights/cities",
    meta: citiestlNl4VkCP8Meta || {},
    alias: citiestlNl4VkCP8Meta?.alias || [],
    redirect: citiestlNl4VkCP8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___en-US",
    path: indexqdiNVEadLOMeta?.path ?? "/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___ca",
    path: indexqdiNVEadLOMeta?.path ?? "/ca/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___nl",
    path: indexqdiNVEadLOMeta?.path ?? "/nl/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___uk",
    path: indexqdiNVEadLOMeta?.path ?? "/uk/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___de",
    path: indexqdiNVEadLOMeta?.path ?? "/de/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___mx",
    path: indexqdiNVEadLOMeta?.path ?? "/mx/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___fr",
    path: indexqdiNVEadLOMeta?.path ?? "/fr/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdiNVEadLOMeta?.name ?? "integrations___fr-ca",
    path: indexqdiNVEadLOMeta?.path ?? "/fr-ca/integrations",
    meta: indexqdiNVEadLOMeta || {},
    alias: indexqdiNVEadLOMeta?.alias || [],
    redirect: indexqdiNVEadLOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___en-US",
    path: indexwOHy0atksAMeta?.path ?? "/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___ca",
    path: indexwOHy0atksAMeta?.path ?? "/ca/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___nl",
    path: indexwOHy0atksAMeta?.path ?? "/nl/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___uk",
    path: indexwOHy0atksAMeta?.path ?? "/uk/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___de",
    path: indexwOHy0atksAMeta?.path ?? "/de/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___mx",
    path: indexwOHy0atksAMeta?.path ?? "/mx/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___fr",
    path: indexwOHy0atksAMeta?.path ?? "/fr/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexwOHy0atksAMeta?.name ?? "invite___fr-ca",
    path: indexwOHy0atksAMeta?.path ?? "/fr-ca/invite",
    meta: indexwOHy0atksAMeta || {},
    alias: indexwOHy0atksAMeta?.alias || [],
    redirect: indexwOHy0atksAMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___en-US",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___ca",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/ca/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___nl",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/nl/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___uk",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/uk/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___de",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/de/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___mx",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/mx/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___fr",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/fr/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45more3pAEqWdyZLMeta?.name ?? "learn-more___fr-ca",
    path: learn_45more3pAEqWdyZLMeta?.path ?? "/fr-ca/learn-more",
    meta: learn_45more3pAEqWdyZLMeta || {},
    alias: learn_45more3pAEqWdyZLMeta?.alias || [],
    redirect: learn_45more3pAEqWdyZLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticesPIoeJo4eeMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticesPIoeJo4eeMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticesPIoeJo4eeMeta || {},
    alias: candidate_45privacy_45noticesPIoeJo4eeMeta?.alias || [],
    redirect: candidate_45privacy_45noticesPIoeJo4eeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentlVXvDbYqg7Meta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentlVXvDbYqg7Meta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentlVXvDbYqg7Meta || {},
    alias: cookie_45consentlVXvDbYqg7Meta?.alias || [],
    redirect: cookie_45consentlVXvDbYqg7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyxIrnS5HxPrMeta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyxIrnS5HxPrMeta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyxIrnS5HxPrMeta || {},
    alias: cookie_45policyxIrnS5HxPrMeta?.alias || [],
    redirect: cookie_45policyxIrnS5HxPrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumLG9b50gm4fMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumLG9b50gm4fMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumLG9b50gm4fMeta || {},
    alias: data_45protection_45addendumLG9b50gm4fMeta?.alias || [],
    redirect: data_45protection_45addendumLG9b50gm4fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnGvFqngXOXMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsnGvFqngXOXMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsnGvFqngXOXMeta || {},
    alias: express_45order_45termsnGvFqngXOXMeta?.alias || [],
    redirect: express_45order_45termsnGvFqngXOXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyPFBg6KynHGMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantyPFBg6KynHGMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantyPFBg6KynHGMeta || {},
    alias: hardware_45warrantyPFBg6KynHGMeta?.alias || [],
    redirect: hardware_45warrantyPFBg6KynHGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slanzaiBNW7LhMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45slanzaiBNW7LhMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45slanzaiBNW7LhMeta || {},
    alias: hosted_45software_45slanzaiBNW7LhMeta?.alias || [],
    redirect: hosted_45software_45slanzaiBNW7LhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIUUKVYpNXjMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductIUUKVYpNXjMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIUUKVYpNXjMeta || {},
    alias: partner_45code_45of_45conductIUUKVYpNXjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIUUKVYpNXjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicesj6RgclCDqMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45servicesj6RgclCDqMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicesj6RgclCDqMeta || {},
    alias: platform_45terms_45of_45servicesj6RgclCDqMeta?.alias || [],
    redirect: platform_45terms_45of_45servicesj6RgclCDqMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___en-US",
    path: privacyaDC5G9dVb9Meta?.path ?? "/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___ca",
    path: privacyaDC5G9dVb9Meta?.path ?? "/ca/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___nl",
    path: privacyaDC5G9dVb9Meta?.path ?? "/nl/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___uk",
    path: privacyaDC5G9dVb9Meta?.path ?? "/uk/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___de",
    path: privacyaDC5G9dVb9Meta?.path ?? "/de/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___mx",
    path: privacyaDC5G9dVb9Meta?.path ?? "/mx/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___fr",
    path: privacyaDC5G9dVb9Meta?.path ?? "/fr/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaDC5G9dVb9Meta?.name ?? "legal-privacy___fr-ca",
    path: privacyaDC5G9dVb9Meta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyaDC5G9dVb9Meta || {},
    alias: privacyaDC5G9dVb9Meta?.alias || [],
    redirect: privacyaDC5G9dVb9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceDAoIzeRBL4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatacICpL7NClFMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdatacICpL7NClFMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdatacICpL7NClFMeta || {},
    alias: requestingcustomerdatacICpL7NClFMeta?.alias || [],
    redirect: requestingcustomerdatacICpL7NClFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___en-US",
    path: securitymWFKlXBN9xMeta?.path ?? "/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___ca",
    path: securitymWFKlXBN9xMeta?.path ?? "/ca/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___nl",
    path: securitymWFKlXBN9xMeta?.path ?? "/nl/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___uk",
    path: securitymWFKlXBN9xMeta?.path ?? "/uk/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___de",
    path: securitymWFKlXBN9xMeta?.path ?? "/de/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___mx",
    path: securitymWFKlXBN9xMeta?.path ?? "/mx/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___fr",
    path: securitymWFKlXBN9xMeta?.path ?? "/fr/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securitymWFKlXBN9xMeta?.name ?? "legal-security___fr-ca",
    path: securitymWFKlXBN9xMeta?.path ?? "/fr-ca/legal/security",
    meta: securitymWFKlXBN9xMeta || {},
    alias: securitymWFKlXBN9xMeta?.alias || [],
    redirect: securitymWFKlXBN9xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportH0ofYomYcFMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreportH0ofYomYcFMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreportH0ofYomYcFMeta || {},
    alias: transparencyreportH0ofYomYcFMeta?.alias || [],
    redirect: transparencyreportH0ofYomYcFMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceIoEaa1Gxy7Meta || {},
    alias: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.alias || [],
    redirect: website_45terms_45of_45serviceIoEaa1Gxy7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesMvEeBisztLMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesMvEeBisztLMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesMvEeBisztLMeta || {},
    alias: your_45privacy_45choicesMvEeBisztLMeta?.alias || [],
    redirect: your_45privacy_45choicesMvEeBisztLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___en-US",
    path: pricingojgPla2JoKMeta?.path ?? "/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___ca",
    path: pricingojgPla2JoKMeta?.path ?? "/ca/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___nl",
    path: pricingojgPla2JoKMeta?.path ?? "/nl/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___uk",
    path: pricingojgPla2JoKMeta?.path ?? "/uk/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___de",
    path: pricingojgPla2JoKMeta?.path ?? "/de/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___mx",
    path: pricingojgPla2JoKMeta?.path ?? "/mx/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___fr",
    path: pricingojgPla2JoKMeta?.path ?? "/fr/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingojgPla2JoKMeta?.name ?? "page-pricing___fr-ca",
    path: pricingojgPla2JoKMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingojgPla2JoKMeta || {},
    alias: pricingojgPla2JoKMeta?.alias || [],
    redirect: pricingojgPla2JoKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H5kPJcMJ9fMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93H5kPJcMJ9fMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93H5kPJcMJ9fMeta || {},
    alias: _91slug_93H5kPJcMJ9fMeta?.alias || [],
    redirect: _91slug_93H5kPJcMJ9fMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___en-US",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___ca",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___nl",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___uk",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___de",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___mx",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___fr",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EhqHMnYdu4Meta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93EhqHMnYdu4Meta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93EhqHMnYdu4Meta || {},
    alias: _91slug_93EhqHMnYdu4Meta?.alias || [],
    redirect: _91slug_93EhqHMnYdu4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingKgZxoU78QRMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingKgZxoU78QRMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingKgZxoU78QRMeta || {},
    alias: asset_45trackingKgZxoU78QRMeta?.alias || [],
    redirect: asset_45trackingKgZxoU78QRMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camst0k3JFuekSMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camst0k3JFuekSMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camst0k3JFuekSMeta || {},
    alias: best_45dash_45camst0k3JFuekSMeta?.alias || [],
    redirect: best_45dash_45camst0k3JFuekSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___en-US",
    path: construction9EDcM94YgTMeta?.path ?? "/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___ca",
    path: construction9EDcM94YgTMeta?.path ?? "/ca/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___nl",
    path: construction9EDcM94YgTMeta?.path ?? "/nl/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___uk",
    path: construction9EDcM94YgTMeta?.path ?? "/uk/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___de",
    path: construction9EDcM94YgTMeta?.path ?? "/de/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___mx",
    path: construction9EDcM94YgTMeta?.path ?? "/mx/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___fr",
    path: construction9EDcM94YgTMeta?.path ?? "/fr/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: construction9EDcM94YgTMeta?.name ?? "pages-construction___fr-ca",
    path: construction9EDcM94YgTMeta?.path ?? "/fr-ca/pages/construction",
    meta: construction9EDcM94YgTMeta || {},
    alias: construction9EDcM94YgTMeta?.alias || [],
    redirect: construction9EDcM94YgTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementQXcWNKA3xrMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementQXcWNKA3xrMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementQXcWNKA3xrMeta || {},
    alias: fleet_45managementQXcWNKA3xrMeta?.alias || [],
    redirect: fleet_45managementQXcWNKA3xrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___en-US",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___ca",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/ca/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___nl",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/nl/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___uk",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/uk/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___de",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/de/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___mx",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/mx/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___fr",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/fr/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideGJHjHEw3G7Meta?.name ?? "pages-nationwide___fr-ca",
    path: nationwideGJHjHEw3G7Meta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwideGJHjHEw3G7Meta || {},
    alias: nationwideGJHjHEw3G7Meta?.alias || [],
    redirect: nationwideGJHjHEw3G7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingRfaSFssRSpMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingRfaSFssRSpMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingRfaSFssRSpMeta || {},
    alias: safety_45coachingRfaSFssRSpMeta?.alias || [],
    redirect: safety_45coachingRfaSFssRSpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___en-US",
    path: unifirstra1LtM3kwsMeta?.path ?? "/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___ca",
    path: unifirstra1LtM3kwsMeta?.path ?? "/ca/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___nl",
    path: unifirstra1LtM3kwsMeta?.path ?? "/nl/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___uk",
    path: unifirstra1LtM3kwsMeta?.path ?? "/uk/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___de",
    path: unifirstra1LtM3kwsMeta?.path ?? "/de/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___mx",
    path: unifirstra1LtM3kwsMeta?.path ?? "/mx/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___fr",
    path: unifirstra1LtM3kwsMeta?.path ?? "/fr/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstra1LtM3kwsMeta?.name ?? "pages-unifirst___fr-ca",
    path: unifirstra1LtM3kwsMeta?.path ?? "/fr-ca/pages/unifirst",
    meta: unifirstra1LtM3kwsMeta || {},
    alias: unifirstra1LtM3kwsMeta?.alias || [],
    redirect: unifirstra1LtM3kwsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___en-US",
    path: pricingMNe5ILFBYiMeta?.path ?? "/pricing",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___ca",
    path: pricingMNe5ILFBYiMeta?.path ?? "/ca/pricing",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___nl",
    path: pricingMNe5ILFBYiMeta?.path ?? "/nl/pricing",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___uk",
    path: pricingMNe5ILFBYiMeta?.path ?? "/uk/pricing",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___de",
    path: pricingMNe5ILFBYiMeta?.path ?? "/de/pricing",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___mx",
    path: pricingMNe5ILFBYiMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___fr",
    path: pricingMNe5ILFBYiMeta?.path ?? "/fr/pricing",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingMNe5ILFBYiMeta?.name ?? "pricing___fr-ca",
    path: pricingMNe5ILFBYiMeta?.path ?? "/fr-ca/pricing",
    meta: pricingMNe5ILFBYiMeta || {},
    alias: pricingMNe5ILFBYiMeta?.alias || [],
    redirect: pricingMNe5ILFBYiMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___ca",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___nl",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___uk",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___de",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/de/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___mx",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___fr",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930S4OZxpSg4Meta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_930S4OZxpSg4Meta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: _91slug_930S4OZxpSg4Meta || {},
    alias: _91slug_930S4OZxpSg4Meta?.alias || [],
    redirect: _91slug_930S4OZxpSg4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___en-US",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___ca",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/ca/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___nl",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/nl/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___uk",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/uk/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___de",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/de/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___mx",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/mx/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___fr",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/fr/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nMart5Xq35Meta?.name ?? "products-slug___fr-ca",
    path: _91slug_93nMart5Xq35Meta?.path ?? "/fr-ca/products/:slug()",
    meta: _91slug_93nMart5Xq35Meta || {},
    alias: _91slug_93nMart5Xq35Meta?.alias || [],
    redirect: _91slug_93nMart5Xq35Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking0T4L0qp016Meta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45tracking0T4L0qp016Meta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45tracking0T4L0qp016Meta || {},
    alias: asset_45tracking0T4L0qp016Meta?.alias || [],
    redirect: asset_45tracking0T4L0qp016Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinglhgBksmxYrMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackinglhgBksmxYrMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackinglhgBksmxYrMeta || {},
    alias: equipment_45trackinglhgBksmxYrMeta?.alias || [],
    redirect: equipment_45trackinglhgBksmxYrMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringBQeGjsFlRgMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringBQeGjsFlRgMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringBQeGjsFlRgMeta || {},
    alias: reefer_45monitoringBQeGjsFlRgMeta?.alias || [],
    redirect: reefer_45monitoringBQeGjsFlRgMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailerse9utMMHHwjMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailerse9utMMHHwjMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailerse9utMMHHwjMeta || {},
    alias: smart_45trailerse9utMMHHwjMeta?.alias || [],
    redirect: smart_45trailerse9utMMHHwjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackinghp76qYtzOeMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackinghp76qYtzOeMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackinghp76qYtzOeMeta || {},
    alias: trailer_45trackinghp76qYtzOeMeta?.alias || [],
    redirect: trailer_45trackinghp76qYtzOeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___en-US",
    path: index6ySWJs70B2Meta?.path ?? "/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___ca",
    path: index6ySWJs70B2Meta?.path ?? "/ca/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___nl",
    path: index6ySWJs70B2Meta?.path ?? "/nl/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___uk",
    path: index6ySWJs70B2Meta?.path ?? "/uk/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___de",
    path: index6ySWJs70B2Meta?.path ?? "/de/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___mx",
    path: index6ySWJs70B2Meta?.path ?? "/mx/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___fr",
    path: index6ySWJs70B2Meta?.path ?? "/fr/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index6ySWJs70B2Meta?.name ?? "products___fr-ca",
    path: index6ySWJs70B2Meta?.path ?? "/fr-ca/products",
    meta: index6ySWJs70B2Meta || {},
    alias: index6ySWJs70B2Meta?.alias || [],
    redirect: index6ySWJs70B2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___ca",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/ca/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___nl",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/nl/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___uk",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/uk/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___de",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/de/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___mx",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/mx/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___fr",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/fr/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EefDTQIqGLMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93EefDTQIqGLMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: _91slug_93EefDTQIqGLMeta || {},
    alias: _91slug_93EefDTQIqGLMeta?.alias || [],
    redirect: _91slug_93EefDTQIqGLMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___en-US",
    path: indexYMBOkOpMfUMeta?.path ?? "/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___ca",
    path: indexYMBOkOpMfUMeta?.path ?? "/ca/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___nl",
    path: indexYMBOkOpMfUMeta?.path ?? "/nl/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___uk",
    path: indexYMBOkOpMfUMeta?.path ?? "/uk/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___de",
    path: indexYMBOkOpMfUMeta?.path ?? "/de/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___mx",
    path: indexYMBOkOpMfUMeta?.path ?? "/mx/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___fr",
    path: indexYMBOkOpMfUMeta?.path ?? "/fr/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMBOkOpMfUMeta?.name ?? "products-models___fr-ca",
    path: indexYMBOkOpMfUMeta?.path ?? "/fr-ca/products/models",
    meta: indexYMBOkOpMfUMeta || {},
    alias: indexYMBOkOpMfUMeta?.alias || [],
    redirect: indexYMBOkOpMfUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingLr19zOofd4Meta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingLr19zOofd4Meta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingLr19zOofd4Meta || {},
    alias: gps_45fleet_45trackingLr19zOofd4Meta?.alias || [],
    redirect: gps_45fleet_45trackingLr19zOofd4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___en-US",
    path: indexNNXyPl0cpIMeta?.path ?? "/products/telematics",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___ca",
    path: indexNNXyPl0cpIMeta?.path ?? "/ca/products/telematics",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___nl",
    path: indexNNXyPl0cpIMeta?.path ?? "/nl/products/telematics",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___uk",
    path: indexNNXyPl0cpIMeta?.path ?? "/uk/products/telematics",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___de",
    path: indexNNXyPl0cpIMeta?.path ?? "/de/products/telematics",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___mx",
    path: indexNNXyPl0cpIMeta?.path ?? "/mx/products/flota",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___fr",
    path: indexNNXyPl0cpIMeta?.path ?? "/fr/products/telematics",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNXyPl0cpIMeta?.name ?? "products-telematics___fr-ca",
    path: indexNNXyPl0cpIMeta?.path ?? "/fr-ca/products/telematics",
    meta: indexNNXyPl0cpIMeta || {},
    alias: indexNNXyPl0cpIMeta?.alias || [],
    redirect: indexNNXyPl0cpIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___en-US",
    path: referralsiNhJyF1AyaMeta?.path ?? "/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___ca",
    path: referralsiNhJyF1AyaMeta?.path ?? "/ca/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___nl",
    path: referralsiNhJyF1AyaMeta?.path ?? "/nl/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___uk",
    path: referralsiNhJyF1AyaMeta?.path ?? "/uk/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___de",
    path: referralsiNhJyF1AyaMeta?.path ?? "/de/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___mx",
    path: referralsiNhJyF1AyaMeta?.path ?? "/mx/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___fr",
    path: referralsiNhJyF1AyaMeta?.path ?? "/fr/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsiNhJyF1AyaMeta?.name ?? "referrals___fr-ca",
    path: referralsiNhJyF1AyaMeta?.path ?? "/fr-ca/referrals",
    meta: referralsiNhJyF1AyaMeta || {},
    alias: referralsiNhJyF1AyaMeta?.alias || [],
    redirect: referralsiNhJyF1AyaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___en-US",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___ca",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___nl",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___uk",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___de",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___mx",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___fr",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HFM4zbbog6Meta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93HFM4zbbog6Meta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93HFM4zbbog6Meta || {},
    alias: _91slug_93HFM4zbbog6Meta?.alias || [],
    redirect: _91slug_93HFM4zbbog6Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___ca",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___nl",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___uk",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___de",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___mx",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___fr",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsyrSO09d6ZJMeta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assetsyrSO09d6ZJMeta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assetsyrSO09d6ZJMeta || {},
    alias: brand_45assetsyrSO09d6ZJMeta?.alias || [],
    redirect: brand_45assetsyrSO09d6ZJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93d1zNoluihcMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93d1zNoluihcMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93d1zNoluihcMeta || {},
    alias: _91slug_93d1zNoluihcMeta?.alias || [],
    redirect: _91slug_93d1zNoluihcMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerDRs3069YgjMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centerDRs3069YgjMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerDRs3069YgjMeta || {},
    alias: email_45preferences_45centerDRs3069YgjMeta?.alias || [],
    redirect: email_45preferences_45centerDRs3069YgjMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0xF5NubprMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93k0xF5NubprMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93k0xF5NubprMeta || {},
    alias: _91slug_93k0xF5NubprMeta?.alias || [],
    redirect: _91slug_93k0xF5NubprMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937mxX4tNrO9Meta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_937mxX4tNrO9Meta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_937mxX4tNrO9Meta || {},
    alias: _91slug_937mxX4tNrO9Meta?.alias || [],
    redirect: _91slug_937mxX4tNrO9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___en-US",
    path: indexHeqiZtuSEkMeta?.path ?? "/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___ca",
    path: indexHeqiZtuSEkMeta?.path ?? "/ca/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___nl",
    path: indexHeqiZtuSEkMeta?.path ?? "/nl/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___uk",
    path: indexHeqiZtuSEkMeta?.path ?? "/uk/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___de",
    path: indexHeqiZtuSEkMeta?.path ?? "/de/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___mx",
    path: indexHeqiZtuSEkMeta?.path ?? "/mx/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___fr",
    path: indexHeqiZtuSEkMeta?.path ?? "/fr/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeqiZtuSEkMeta?.name ?? "resources-experts___fr-ca",
    path: indexHeqiZtuSEkMeta?.path ?? "/fr-ca/resources/experts",
    meta: indexHeqiZtuSEkMeta || {},
    alias: indexHeqiZtuSEkMeta?.alias || [],
    redirect: indexHeqiZtuSEkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___en-US",
    path: indexglmKqaSEXaMeta?.path ?? "/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___ca",
    path: indexglmKqaSEXaMeta?.path ?? "/ca/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___nl",
    path: indexglmKqaSEXaMeta?.path ?? "/nl/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___uk",
    path: indexglmKqaSEXaMeta?.path ?? "/uk/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___de",
    path: indexglmKqaSEXaMeta?.path ?? "/de/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___mx",
    path: indexglmKqaSEXaMeta?.path ?? "/mx/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___fr",
    path: indexglmKqaSEXaMeta?.path ?? "/fr/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexglmKqaSEXaMeta?.name ?? "resources___fr-ca",
    path: indexglmKqaSEXaMeta?.path ?? "/fr-ca/resources",
    meta: indexglmKqaSEXaMeta || {},
    alias: indexglmKqaSEXaMeta?.alias || [],
    redirect: indexglmKqaSEXaMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936yMryG0j4iMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_936yMryG0j4iMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_936yMryG0j4iMeta || {},
    alias: _91slug_936yMryG0j4iMeta?.alias || [],
    redirect: _91slug_936yMryG0j4iMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mDc5oseWTDMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93mDc5oseWTDMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93mDc5oseWTDMeta || {},
    alias: _91slug_93mDc5oseWTDMeta?.alias || [],
    redirect: _91slug_93mDc5oseWTDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___en-US",
    path: index3ikmdZymlMMeta?.path ?? "/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___ca",
    path: index3ikmdZymlMMeta?.path ?? "/ca/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___nl",
    path: index3ikmdZymlMMeta?.path ?? "/nl/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___uk",
    path: index3ikmdZymlMMeta?.path ?? "/uk/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___de",
    path: index3ikmdZymlMMeta?.path ?? "/de/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___mx",
    path: index3ikmdZymlMMeta?.path ?? "/mx/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___fr",
    path: index3ikmdZymlMMeta?.path ?? "/fr/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index3ikmdZymlMMeta?.name ?? "resources-marketplace___fr-ca",
    path: index3ikmdZymlMMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: index3ikmdZymlMMeta || {},
    alias: index3ikmdZymlMMeta?.alias || [],
    redirect: index3ikmdZymlMMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraFRTP9D42J4Meta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsaraFRTP9D42J4Meta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraFRTP9D42J4Meta || {},
    alias: build_45with_45samsaraFRTP9D42J4Meta?.alias || [],
    redirect: build_45with_45samsaraFRTP9D42J4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsmbZloWHpzNMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programsmbZloWHpzNMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsmbZloWHpzNMeta || {},
    alias: evolve_45insurance_45programsmbZloWHpzNMeta?.alias || [],
    redirect: evolve_45insurance_45programsmbZloWHpzNMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___en-US",
    path: index4ZimGfJbxuMeta?.path ?? "/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___ca",
    path: index4ZimGfJbxuMeta?.path ?? "/ca/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___nl",
    path: index4ZimGfJbxuMeta?.path ?? "/nl/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___uk",
    path: index4ZimGfJbxuMeta?.path ?? "/uk/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___de",
    path: index4ZimGfJbxuMeta?.path ?? "/de/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___mx",
    path: index4ZimGfJbxuMeta?.path ?? "/mx/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___fr",
    path: index4ZimGfJbxuMeta?.path ?? "/fr/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index4ZimGfJbxuMeta?.name ?? "resources-partner-programs___fr-ca",
    path: index4ZimGfJbxuMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: index4ZimGfJbxuMeta || {},
    alias: index4ZimGfJbxuMeta?.alias || [],
    redirect: index4ZimGfJbxuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesJA106a29n4Meta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesJA106a29n4Meta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesJA106a29n4Meta || {},
    alias: provide_45servicesJA106a29n4Meta?.alias || [],
    redirect: provide_45servicesJA106a29n4Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara17EphE54zlMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsara17EphE54zlMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsara17EphE54zlMeta || {},
    alias: sell_45samsara17EphE54zlMeta?.alias || [],
    redirect: sell_45samsara17EphE54zlMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___en-US",
    path: touru6TTYStj3UMeta?.path ?? "/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___ca",
    path: touru6TTYStj3UMeta?.path ?? "/ca/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___nl",
    path: touru6TTYStj3UMeta?.path ?? "/nl/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___uk",
    path: touru6TTYStj3UMeta?.path ?? "/uk/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___de",
    path: touru6TTYStj3UMeta?.path ?? "/de/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___mx",
    path: touru6TTYStj3UMeta?.path ?? "/mx/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___fr",
    path: touru6TTYStj3UMeta?.path ?? "/fr/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: touru6TTYStj3UMeta?.name ?? "resources-tour___fr-ca",
    path: touru6TTYStj3UMeta?.path ?? "/fr-ca/resources/tour",
    meta: touru6TTYStj3UMeta || {},
    alias: touru6TTYStj3UMeta?.alias || [],
    redirect: touru6TTYStj3UMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___en-US",
    path: videosNVtEp2QGnmMeta?.path ?? "/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___ca",
    path: videosNVtEp2QGnmMeta?.path ?? "/ca/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___nl",
    path: videosNVtEp2QGnmMeta?.path ?? "/nl/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___uk",
    path: videosNVtEp2QGnmMeta?.path ?? "/uk/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___de",
    path: videosNVtEp2QGnmMeta?.path ?? "/de/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___mx",
    path: videosNVtEp2QGnmMeta?.path ?? "/mx/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___fr",
    path: videosNVtEp2QGnmMeta?.path ?? "/fr/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosNVtEp2QGnmMeta?.name ?? "resources-videos___fr-ca",
    path: videosNVtEp2QGnmMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosNVtEp2QGnmMeta || {},
    alias: videosNVtEp2QGnmMeta?.alias || [],
    redirect: videosNVtEp2QGnmMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___en-US",
    path: indexY4xBPykOXeMeta?.path ?? "/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___ca",
    path: indexY4xBPykOXeMeta?.path ?? "/ca/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___nl",
    path: indexY4xBPykOXeMeta?.path ?? "/nl/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___uk",
    path: indexY4xBPykOXeMeta?.path ?? "/uk/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___de",
    path: indexY4xBPykOXeMeta?.path ?? "/de/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___mx",
    path: indexY4xBPykOXeMeta?.path ?? "/mx/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___fr",
    path: indexY4xBPykOXeMeta?.path ?? "/fr/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4xBPykOXeMeta?.name ?? "roi-calculator___fr-ca",
    path: indexY4xBPykOXeMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexY4xBPykOXeMeta || {},
    alias: indexY4xBPykOXeMeta?.alias || [],
    redirect: indexY4xBPykOXeMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___de",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesUYL8syPueuMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturesUYL8syPueuMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturesUYL8syPueuMeta || {},
    alias: samsara_45venturesUYL8syPueuMeta?.alias || [],
    redirect: samsara_45venturesUYL8syPueuMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___en-US",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___ca",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/ca/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___nl",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/nl/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___uk",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/uk/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___de",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/de/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___mx",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/mx/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___fr",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/fr/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitc9UFn9dovGMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitc9UFn9dovGMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitc9UFn9dovGMeta || {},
    alias: security_45summitc9UFn9dovGMeta?.alias || [],
    redirect: security_45summitc9UFn9dovGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___en-US",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___ca",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___nl",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___uk",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___de",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___mx",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___fr",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EIZFxQMUOTMeta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_93EIZFxQMUOTMeta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_93EIZFxQMUOTMeta || {},
    alias: _91slug_93EIZFxQMUOTMeta?.alias || [],
    redirect: _91slug_93EIZFxQMUOTMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___en-US",
    path: indexFy0M1PAaDQMeta?.path ?? "/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___ca",
    path: indexFy0M1PAaDQMeta?.path ?? "/ca/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___nl",
    path: indexFy0M1PAaDQMeta?.path ?? "/nl/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___uk",
    path: indexFy0M1PAaDQMeta?.path ?? "/uk/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___de",
    path: indexFy0M1PAaDQMeta?.path ?? "/de/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___mx",
    path: indexFy0M1PAaDQMeta?.path ?? "/mx/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___fr",
    path: indexFy0M1PAaDQMeta?.path ?? "/fr/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFy0M1PAaDQMeta?.name ?? "solutions___fr-ca",
    path: indexFy0M1PAaDQMeta?.path ?? "/fr-ca/solutions",
    meta: indexFy0M1PAaDQMeta || {},
    alias: indexFy0M1PAaDQMeta?.alias || [],
    redirect: indexFy0M1PAaDQMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___en-US",
    path: security7lRdzuPB6xMeta?.path ?? "/solutions/security",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___ca",
    path: security7lRdzuPB6xMeta?.path ?? "/ca/solutions/security",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___nl",
    path: security7lRdzuPB6xMeta?.path ?? "/nl/solutions/security",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___uk",
    path: security7lRdzuPB6xMeta?.path ?? "/uk/solutions/security",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___de",
    path: security7lRdzuPB6xMeta?.path ?? "/de/solutions/security",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___mx",
    path: security7lRdzuPB6xMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___fr",
    path: security7lRdzuPB6xMeta?.path ?? "/fr/solutions/security",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: security7lRdzuPB6xMeta?.name ?? "solutions-security___fr-ca",
    path: security7lRdzuPB6xMeta?.path ?? "/fr-ca/solutions/security",
    meta: security7lRdzuPB6xMeta || {},
    alias: security7lRdzuPB6xMeta?.alias || [],
    redirect: security7lRdzuPB6xMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___en-US",
    path: spaceyhQaIpGdb8Meta?.path ?? "/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___ca",
    path: spaceyhQaIpGdb8Meta?.path ?? "/ca/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___nl",
    path: spaceyhQaIpGdb8Meta?.path ?? "/nl/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___uk",
    path: spaceyhQaIpGdb8Meta?.path ?? "/uk/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___de",
    path: spaceyhQaIpGdb8Meta?.path ?? "/de/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___mx",
    path: spaceyhQaIpGdb8Meta?.path ?? "/mx/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___fr",
    path: spaceyhQaIpGdb8Meta?.path ?? "/fr/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceyhQaIpGdb8Meta?.name ?? "space___fr-ca",
    path: spaceyhQaIpGdb8Meta?.path ?? "/fr-ca/space",
    meta: spaceyhQaIpGdb8Meta || {},
    alias: spaceyhQaIpGdb8Meta?.alias || [],
    redirect: spaceyhQaIpGdb8Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___en-US",
    path: sparkkez9nGBlvoMeta?.path ?? "/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___ca",
    path: sparkkez9nGBlvoMeta?.path ?? "/ca/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___nl",
    path: sparkkez9nGBlvoMeta?.path ?? "/nl/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___uk",
    path: sparkkez9nGBlvoMeta?.path ?? "/uk/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___de",
    path: sparkkez9nGBlvoMeta?.path ?? "/de/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___mx",
    path: sparkkez9nGBlvoMeta?.path ?? "/mx/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___fr",
    path: sparkkez9nGBlvoMeta?.path ?? "/fr/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkkez9nGBlvoMeta?.name ?? "spark___fr-ca",
    path: sparkkez9nGBlvoMeta?.path ?? "/fr-ca/spark",
    meta: sparkkez9nGBlvoMeta || {},
    alias: sparkkez9nGBlvoMeta?.alias || [],
    redirect: sparkkez9nGBlvoMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___en-US",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___ca",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___nl",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___uk",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___de",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___mx",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___fr",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBBhFtmnQ9Meta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93oBBhFtmnQ9Meta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93oBBhFtmnQ9Meta || {},
    alias: _91slug_93oBBhFtmnQ9Meta?.alias || [],
    redirect: _91slug_93oBBhFtmnQ9Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Gyi3SmoSHnMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93Gyi3SmoSHnMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93Gyi3SmoSHnMeta || {},
    alias: _91slug_93Gyi3SmoSHnMeta?.alias || [],
    redirect: _91slug_93Gyi3SmoSHnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___en-US",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___ca",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___nl",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___uk",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___de",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___mx",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___fr",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jCRc3S3qNSMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93jCRc3S3qNSMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93jCRc3S3qNSMeta || {},
    alias: _91slug_93jCRc3S3qNSMeta?.alias || [],
    redirect: _91slug_93jCRc3S3qNSMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy8NixGL9zDkMeta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policy8NixGL9zDkMeta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policy8NixGL9zDkMeta || {},
    alias: apps_45privacy_45policy8NixGL9zDkMeta?.alias || [],
    redirect: apps_45privacy_45policy8NixGL9zDkMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq7p5BCSzcLKMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faq7p5BCSzcLKMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faq7p5BCSzcLKMeta || {},
    alias: customer_45referrals_45faq7p5BCSzcLKMeta?.alias || [],
    redirect: customer_45referrals_45faq7p5BCSzcLKMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishylxVKF9XHJMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanishylxVKF9XHJMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanishylxVKF9XHJMeta || {},
    alias: driver_45training_45spanishylxVKF9XHJMeta?.alias || [],
    redirect: driver_45training_45spanishylxVKF9XHJMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___ca",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___nl",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___uk",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___de",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___mx",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___fr",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingiJLFBkGi2oMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingiJLFBkGi2oMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingiJLFBkGi2oMeta || {},
    alias: driver_45trainingiJLFBkGi2oMeta?.alias || [],
    redirect: driver_45trainingiJLFBkGi2oMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___en-US",
    path: indexlyFPWGe0f2Meta?.path ?? "/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___ca",
    path: indexlyFPWGe0f2Meta?.path ?? "/ca/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___nl",
    path: indexlyFPWGe0f2Meta?.path ?? "/nl/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___uk",
    path: indexlyFPWGe0f2Meta?.path ?? "/uk/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___de",
    path: indexlyFPWGe0f2Meta?.path ?? "/de/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___mx",
    path: indexlyFPWGe0f2Meta?.path ?? "/mx/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___fr",
    path: indexlyFPWGe0f2Meta?.path ?? "/fr/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexlyFPWGe0f2Meta?.name ?? "support___fr-ca",
    path: indexlyFPWGe0f2Meta?.path ?? "/fr-ca/support",
    meta: indexlyFPWGe0f2Meta || {},
    alias: indexlyFPWGe0f2Meta?.alias || [],
    redirect: indexlyFPWGe0f2Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___en-US",
    path: industrialOLJRC2ikC7Meta?.path ?? "/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___ca",
    path: industrialOLJRC2ikC7Meta?.path ?? "/ca/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___nl",
    path: industrialOLJRC2ikC7Meta?.path ?? "/nl/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___uk",
    path: industrialOLJRC2ikC7Meta?.path ?? "/uk/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___de",
    path: industrialOLJRC2ikC7Meta?.path ?? "/de/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___mx",
    path: industrialOLJRC2ikC7Meta?.path ?? "/mx/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___fr",
    path: industrialOLJRC2ikC7Meta?.path ?? "/fr/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialOLJRC2ikC7Meta?.name ?? "support-industrial___fr-ca",
    path: industrialOLJRC2ikC7Meta?.path ?? "/fr-ca/support/industrial",
    meta: industrialOLJRC2ikC7Meta || {},
    alias: industrialOLJRC2ikC7Meta?.alias || [],
    redirect: industrialOLJRC2ikC7Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementecIyLAtplUMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementecIyLAtplUMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementecIyLAtplUMeta || {},
    alias: modern_45slavery_45statementecIyLAtplUMeta?.alias || [],
    redirect: modern_45slavery_45statementecIyLAtplUMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyinNrZVgiVsMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyinNrZVgiVsMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyinNrZVgiVsMeta || {},
    alias: apps_45privacy_45policyinNrZVgiVsMeta?.alias || [],
    redirect: apps_45privacy_45policyinNrZVgiVsMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___en-US",
    path: indexklJPqTVvESMeta?.path ?? "/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___ca",
    path: indexklJPqTVvESMeta?.path ?? "/ca/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___nl",
    path: indexklJPqTVvESMeta?.path ?? "/nl/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___uk",
    path: indexklJPqTVvESMeta?.path ?? "/uk/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___de",
    path: indexklJPqTVvESMeta?.path ?? "/de/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___mx",
    path: indexklJPqTVvESMeta?.path ?? "/mx/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___fr",
    path: indexklJPqTVvESMeta?.path ?? "/fr/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexklJPqTVvESMeta?.name ?? "support-privacy___fr-ca",
    path: indexklJPqTVvESMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexklJPqTVvESMeta || {},
    alias: indexklJPqTVvESMeta?.alias || [],
    redirect: indexklJPqTVvESMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___de",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresUUDptyfwvtMeta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuresUUDptyfwvtMeta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuresUUDptyfwvtMeta || {},
    alias: special_45featuresUUDptyfwvtMeta?.alias || [],
    redirect: special_45featuresUUDptyfwvtMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listH1aP4A6uJhMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listH1aP4A6uJhMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listH1aP4A6uJhMeta || {},
    alias: subprocessor_45listH1aP4A6uJhMeta?.alias || [],
    redirect: subprocessor_45listH1aP4A6uJhMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___en-US",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___ca",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/ca/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___uk",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/uk/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articles35BOrk2MaXMeta?.name ?? "support-support-kb-articles___fr-ca",
    path: support_45kb_45articles35BOrk2MaXMeta?.path ?? "/fr-ca/support/support-kb-articles",
    meta: support_45kb_45articles35BOrk2MaXMeta || {},
    alias: support_45kb_45articles35BOrk2MaXMeta?.alias || [],
    redirect: support_45kb_45articles35BOrk2MaXMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagekwuhtRL1oGMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pagekwuhtRL1oGMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pagekwuhtRL1oGMeta || {},
    alias: thank_45you_45pagekwuhtRL1oGMeta?.alias || [],
    redirect: thank_45you_45pagekwuhtRL1oGMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruiting0md9fKbwk5Meta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruiting0md9fKbwk5Meta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruiting0md9fKbwk5Meta || {},
    alias: thankyou_45recruiting0md9fKbwk5Meta?.alias || [],
    redirect: thankyou_45recruiting0md9fKbwk5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVbKvFlodUCMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralVbKvFlodUCMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralVbKvFlodUCMeta || {},
    alias: thankyou_45referralVbKvFlodUCMeta?.alias || [],
    redirect: thankyou_45referralVbKvFlodUCMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___en-US",
    path: thankyoudiijGUmQYvMeta?.path ?? "/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___ca",
    path: thankyoudiijGUmQYvMeta?.path ?? "/ca/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___nl",
    path: thankyoudiijGUmQYvMeta?.path ?? "/nl/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___uk",
    path: thankyoudiijGUmQYvMeta?.path ?? "/uk/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___de",
    path: thankyoudiijGUmQYvMeta?.path ?? "/de/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___mx",
    path: thankyoudiijGUmQYvMeta?.path ?? "/mx/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___fr",
    path: thankyoudiijGUmQYvMeta?.path ?? "/fr/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoudiijGUmQYvMeta?.name ?? "thankyou___fr-ca",
    path: thankyoudiijGUmQYvMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyoudiijGUmQYvMeta || {},
    alias: thankyoudiijGUmQYvMeta?.alias || [],
    redirect: thankyoudiijGUmQYvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: elitehFcZWe7LCDMeta?.name ?? "tiers-elite___uk",
    path: elitehFcZWe7LCDMeta?.path ?? "/uk/tiers/elite",
    meta: elitehFcZWe7LCDMeta || {},
    alias: elitehFcZWe7LCDMeta?.alias || [],
    redirect: elitehFcZWe7LCDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitehFcZWe7LCDMeta?.name ?? "tiers-elite___en-US",
    path: elitehFcZWe7LCDMeta?.path ?? "/tiers/elite",
    meta: elitehFcZWe7LCDMeta || {},
    alias: elitehFcZWe7LCDMeta?.alias || [],
    redirect: elitehFcZWe7LCDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitehFcZWe7LCDMeta?.name ?? "tiers-elite___ca",
    path: elitehFcZWe7LCDMeta?.path ?? "/ca/tiers/elite",
    meta: elitehFcZWe7LCDMeta || {},
    alias: elitehFcZWe7LCDMeta?.alias || [],
    redirect: elitehFcZWe7LCDMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusMfPSOKz7TnMeta?.name ?? "tiers-plus___uk",
    path: plusMfPSOKz7TnMeta?.path ?? "/uk/tiers/plus",
    meta: plusMfPSOKz7TnMeta || {},
    alias: plusMfPSOKz7TnMeta?.alias || [],
    redirect: plusMfPSOKz7TnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusMfPSOKz7TnMeta?.name ?? "tiers-plus___en-US",
    path: plusMfPSOKz7TnMeta?.path ?? "/tiers/plus",
    meta: plusMfPSOKz7TnMeta || {},
    alias: plusMfPSOKz7TnMeta?.alias || [],
    redirect: plusMfPSOKz7TnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusMfPSOKz7TnMeta?.name ?? "tiers-plus___ca",
    path: plusMfPSOKz7TnMeta?.path ?? "/ca/tiers/plus",
    meta: plusMfPSOKz7TnMeta || {},
    alias: plusMfPSOKz7TnMeta?.alias || [],
    redirect: plusMfPSOKz7TnMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierwBv4D9fceOMeta?.name ?? "tiers-premier___uk",
    path: premierwBv4D9fceOMeta?.path ?? "/uk/tiers/premier",
    meta: premierwBv4D9fceOMeta || {},
    alias: premierwBv4D9fceOMeta?.alias || [],
    redirect: premierwBv4D9fceOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierwBv4D9fceOMeta?.name ?? "tiers-premier___en-US",
    path: premierwBv4D9fceOMeta?.path ?? "/tiers/premier",
    meta: premierwBv4D9fceOMeta || {},
    alias: premierwBv4D9fceOMeta?.alias || [],
    redirect: premierwBv4D9fceOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierwBv4D9fceOMeta?.name ?? "tiers-premier___ca",
    path: premierwBv4D9fceOMeta?.path ?? "/ca/tiers/premier",
    meta: premierwBv4D9fceOMeta || {},
    alias: premierwBv4D9fceOMeta?.alias || [],
    redirect: premierwBv4D9fceOMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterXcHBagvgkEMeta?.name ?? "tiers-starter___uk",
    path: starterXcHBagvgkEMeta?.path ?? "/uk/tiers/starter",
    meta: starterXcHBagvgkEMeta || {},
    alias: starterXcHBagvgkEMeta?.alias || [],
    redirect: starterXcHBagvgkEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterXcHBagvgkEMeta?.name ?? "tiers-starter___en-US",
    path: starterXcHBagvgkEMeta?.path ?? "/tiers/starter",
    meta: starterXcHBagvgkEMeta || {},
    alias: starterXcHBagvgkEMeta?.alias || [],
    redirect: starterXcHBagvgkEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterXcHBagvgkEMeta?.name ?? "tiers-starter___ca",
    path: starterXcHBagvgkEMeta?.path ?? "/ca/tiers/starter",
    meta: starterXcHBagvgkEMeta || {},
    alias: starterXcHBagvgkEMeta?.alias || [],
    redirect: starterXcHBagvgkEMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___en-US",
    path: processREmDvjBLPpMeta?.path ?? "/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___ca",
    path: processREmDvjBLPpMeta?.path ?? "/ca/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___nl",
    path: processREmDvjBLPpMeta?.path ?? "/nl/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___uk",
    path: processREmDvjBLPpMeta?.path ?? "/uk/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___de",
    path: processREmDvjBLPpMeta?.path ?? "/de/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___mx",
    path: processREmDvjBLPpMeta?.path ?? "/mx/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___fr",
    path: processREmDvjBLPpMeta?.path ?? "/fr/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processREmDvjBLPpMeta?.name ?? "trial-process___fr-ca",
    path: processREmDvjBLPpMeta?.path ?? "/fr-ca/trial/process",
    meta: processREmDvjBLPpMeta || {},
    alias: processREmDvjBLPpMeta?.alias || [],
    redirect: processREmDvjBLPpMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportqCvJQbKLIIMeta?.name ?? "warranty-support___uk",
    path: supportqCvJQbKLIIMeta?.path ?? "/uk/warranty/support",
    meta: supportqCvJQbKLIIMeta || {},
    alias: supportqCvJQbKLIIMeta?.alias || [],
    redirect: supportqCvJQbKLIIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportqCvJQbKLIIMeta?.name ?? "warranty-support___en-US",
    path: supportqCvJQbKLIIMeta?.path ?? "/warranty/support",
    meta: supportqCvJQbKLIIMeta || {},
    alias: supportqCvJQbKLIIMeta?.alias || [],
    redirect: supportqCvJQbKLIIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportqCvJQbKLIIMeta?.name ?? "warranty-support___ca",
    path: supportqCvJQbKLIIMeta?.path ?? "/ca/warranty/support",
    meta: supportqCvJQbKLIIMeta || {},
    alias: supportqCvJQbKLIIMeta?.alias || [],
    redirect: supportqCvJQbKLIIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportqCvJQbKLIIMeta?.name ?? "warranty-support___ie",
    path: supportqCvJQbKLIIMeta?.path ?? "/ie/warranty/support",
    meta: supportqCvJQbKLIIMeta || {},
    alias: supportqCvJQbKLIIMeta?.alias || [],
    redirect: supportqCvJQbKLIIMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___de",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nj3wHXoVONMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93nj3wHXoVONMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93nj3wHXoVONMeta || {},
    alias: _91slug_93nj3wHXoVONMeta?.alias || [],
    redirect: _91slug_93nj3wHXoVONMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___en-US",
    path: indexd7ksET0HKvMeta?.path ?? "/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___ca",
    path: indexd7ksET0HKvMeta?.path ?? "/ca/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___nl",
    path: indexd7ksET0HKvMeta?.path ?? "/nl/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___uk",
    path: indexd7ksET0HKvMeta?.path ?? "/uk/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___de",
    path: indexd7ksET0HKvMeta?.path ?? "/de/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___mx",
    path: indexd7ksET0HKvMeta?.path ?? "/mx/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___fr",
    path: indexd7ksET0HKvMeta?.path ?? "/fr/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexd7ksET0HKvMeta?.name ?? "webinars___fr-ca",
    path: indexd7ksET0HKvMeta?.path ?? "/fr-ca/webinars",
    meta: indexd7ksET0HKvMeta || {},
    alias: indexd7ksET0HKvMeta?.alias || [],
    redirect: indexd7ksET0HKvMeta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_938L642VeZp5Meta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_938L642VeZp5Meta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_938L642VeZp5Meta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_938L642VeZp5Meta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_938L642VeZp5Meta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_938L642VeZp5Meta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_938L642VeZp5Meta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938L642VeZp5Meta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_938L642VeZp5Meta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_938L642VeZp5Meta || {},
    alias: _91slug_938L642VeZp5Meta?.alias || [],
    redirect: _91slug_938L642VeZp5Meta?.redirect,
    component: () => import("/codebuild/output/src3863098639/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]